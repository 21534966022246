import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/dashboard.vue";
import store from "../store";
//import Suspects from "../views/suspects.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Dasboard",
		component: Dashboard
	},
	{
		path: "/connexion",
		name: "Connexion",
		component: () => import(/* webpackChunkName: "connexion" */ "../views/connexion.vue")
	},
	{
		path: "/cgv",
		name: "CGV",
		component: () => import(/* webpackChunkName: "cgv" */ "../views/cgv.vue")
	},
	{
		path: "/cgv/:id",
		name: "Signature de CGV",
		component: () => import(/* webpackChunkName: "cgv" */ "../views/cgv-single.vue")
	},
	{
		path: "/signature/:id",
		name: "Signature",
		component: () => import(/* webpackChunkName: "signature" */ "../views/signature.vue")
	},
	{
		path: "/moncompte",
		name: "Mon compte",
		component: () => import(/* webpackChunkName: "client" */ "../views/moncompte.vue")
	},
	{
		path: "/fields",
		name: "Fields",
		component: () => import(/* webpackChunkName: "client" */ "../views/fields.vue")
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	if (!store.state.connected && to.path != "/connexion") {
		next({ path: "/connexion" });
	} else {
		next();
	}
	if (to.path == "/connexion" && store.state.connected) {
		next({ path: "/" });
	}
	if (to.path == "/fields" && store.state.user.fields.role != "administrateur") {
		next({ path: "/" });
	}
});

export default router;
