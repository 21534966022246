<template>
	<div class="box">
		<div class="part">
			<h2>Volumes réalisés 2023</h2>
			<div class="data"><label>PAC</label> {{ client.fields["PAC"] }}</div>
			<div class="data"><label>Clim</label> {{ client.fields["Clim"] }}</div>
			<div class="data"><label>Thermodynamique</label> {{ client.fields["Thermodynamique"] }}</div>
			<div class="data"><label>Murales</label> {{ client.fields["Murales"] }}</div>
			<!-- <div class="data"><label>Ch.Fioul</label> {{ client.fields["Ch.Fioul"] }}</div> -->
			<div class="data"><label>Ch.Gaz</label> {{ client.fields["Ch.Gaz"] }}</div>
			<div class="data"><label>MGP</label> {{ client.fields["MGP"] }}</div>
			<div class="data"><label>Solaire</label> {{ client.fields["Solaire"] }}</div>
			<div class="data"><label>PV</label> {{ client.fields["PV"] }}</div>
			<div class="data"><label>Ch.Bois</label> {{ client.fields["Ch.Bois"] }}</div>
			<!-- <div class="data"><label>CA Vitoset</label> {{ client.fields["CA Vitoset"] }}</div> -->
			<div class="data total">
				<label>Chiffre d'affaire réalisé</label>
				{{ client.fields["Chiffres d'affaires réalisés"] | currency }}
			</div>
		</div>

		<div class="part">
			<h2>Volumes prévus 2025</h2>
			<div class="data">
				<label>PAC</label>
				<input type="number" v-model="pac" min="0" required @change="calculTotal($event, 'pac_objectif')" />
			</div>
			<div class="data">
				<label>Clim</label>
				<input type="number" v-model="clim" min="0" required @change="calculTotal($event, 'clim_objectif')" />
			</div>
			<div class="data">
				<label>Thermodynamique</label>
				<input type="number" min="0" v-model="thermodynamique" required @change="calculTotal($event, 'thermodynamique_objectif')" />
			</div>
			<div class="data">
				<label>Murales</label>
				<input type="number" min="0" v-model="murales" required @change="calculTotal($event, 'murales_objectif')" />
			</div>
			<div class="data">
				<label>Ch.Gaz</label>
				<input type="number" min="0" v-model="gaz" required @change="calculTotal($event, 'gaz_objectif')" />
			</div>
			<div class="data">
				<label>MGP</label>
				<input type="number" min="0" v-model="mgp" required @change="calculTotal($event, 'mgp_objectif')" />
			</div>
			<div class="data">
				<label>Solaire</label>
				<input type="number" min="0" v-model="solaire" required @change="calculTotal($event, 'solaire_objectif')" />
			</div>
			<div class="data">
				<label>PV</label>
				<input type="number" min="0" v-model="pv" required @change="calculTotal($event, 'pv_objectif')" />
			</div>
			<div class="data">
				<label>Ch.Bois</label>
				<input type="number" min="0" v-model="bois" required @change="calculTotal($event, 'bois_objectif')" />
			</div>
			
			<div class="data total right" v-show="false">
				<label>Chiffres d'affaires objectif théorique</label>
				{{ total | currency }}
			</div>
			<div class="data total">
				<label>Chiffres d'affaires objectif convenu</label>
				<input type="number" min="0" v-model="convenu" @change="changeConvenu()" />
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Volumes",
	components: {},
	data() {
		return {
			pac: this.$store.state.current_client.fields.pac_objectif || 0,
			clim: this.$store.state.current_client.fields.clim_objectif || 0,
			thermodynamique: this.$store.state.current_client.fields.thermodynamique_objectif || 0,
			murales: this.$store.state.current_client.fields.murales_objectif || 0,
			gaz: this.$store.state.current_client.fields.gaz_objectif || 0,
			mgp: this.$store.state.current_client.fields.mgp_objectif || 0,
			solaire: this.$store.state.current_client.fields.solaire_objectif || 0,
			pv: this.$store.state.current_client.fields.pv_objectif || 0,
			bois: this.$store.state.current_client.fields.bois_objectif || 0,
			total: this.$store.state.current_client.fields["Chiffre affaires objectif théorique"] || 0,
			convenu: this.$store.state.current_client.fields["Chiffre affaires objectif convenu"] || 0
		};
	},
	mounted() {
		this.calculTotalStart();
	},
	computed: {
		client() {
			return this.$store.state.current_client;
		}
	},
	methods: {
		changeConvenu() {
			if (this.convenu <= 0) {
				this.convenu = this.total;
			}
			this.$store.commit("updateStoreField", {
				field: "Chiffre affaires objectif convenu",
				value: parseInt(this.convenu)
			});
		},
		calculTotalStart() {
			this.changeConvenu();
			this.verifVide();
			this.total =
				parseInt(this.pac) * 3200 +
				parseInt(this.clim) * 640 +
				parseInt(this.thermodynamique) * 1570 +
				parseInt(this.murales) * 2100 +
				parseInt(this.gaz) * 5370 +
				parseInt(this.mgp) * 9660 +
				parseInt(this.solaire) * 495 +
				parseInt(this.pv) * 350 +
				parseInt(this.bois) * 7660;
			this.total = this.total * 1.32;
			this.total = Math.round(this.total, 2);
			this.$store.commit("updateStoreField", {
				field: "Chiffre affaires objectif théorique",
				value: parseInt(this.total)
			});
			if (this.convenu < this.total) {
				this.convenu = this.total;
				this.$store.commit("updateStoreField", {
					field: "Chiffre affaires objectif convenu",
					value: parseInt(this.convenu)
				});
			}
		},
		verifVide() {
			if (this.pac.length === 0) {
				this.pac = 0;
			}
			if (this.clim.length === 0) {
				this.clim = 0;
			}
			if (this.thermodynamique.length === 0) {
				this.thermodynamique = 0;
			}
			if (this.murales.length === 0) {
				this.murales = 0;
			}
			if (this.gaz.length === 0) {
				this.gaz = 0;
			}
			if (this.mgp.length === 0) {
				this.mgp = 0;
			}
			if (this.solaire.length === 0) {
				this.solaire = 0;
			}
			if (this.pv.length === 0) {
				this.pv = 0;
			}
			if (this.bois.length === 0) {
				this.bois = 0;
			}
		},
		calculTotal(e, field) {
			this.verifVide();

			this.$store.commit("updateStoreField", {
				field: field,
				value: parseInt(e.target.value)
			});
			this.total =
				parseInt(this.pac) * 3073 + 
				parseInt(this.clim) * 614 + 
				parseInt(this.thermodynamique) * 1508 +
				parseInt(this.murales) * 2020 +
				parseInt(this.gaz) * 5166 +
				parseInt(this.mgp) * 9294 +
				parseInt(this.solaire) * 475 +
				parseInt(this.pv) * 335 +
				parseInt(this.bois) * 7369;
			this.total = this.total * 1.32;
			this.total = Math.round(this.total, 2);
			this.$store.commit("updateStoreField", {
				field: "Chiffre affaires objectif théorique",
				value: parseInt(this.total)
			});
			if (this.convenu <= 0) {
				this.convenu = this.total;
				this.$store.commit("updateStoreField", {
					field: "Chiffre affaires objectif convenu",
					value: parseInt(this.convenu)
				});
			}
		}
	}
};
</script>

<style lang="scss"></style>
