<template>
	<div class="pageprint" id="page5">
		<h1>Charte de partenariat</h1>
		<div class="zone-texte">
			<div v-if="client.fields['Classe de partenaire'] === 'Basic (PAR)'" v-html="charte_partenariat_basic"></div>
			<div v-if="client.fields['Classe de partenaire'] === 'Partenaire (MAP)'" v-html="charte_partenariat_partenaire"></div>
			<div v-if="client.fields['Classe de partenaire'] === 'Prospect (EK)'" v-html="charte_partenariat_prospect"></div>
			<div v-if="client.fields['Classe de partenaire'] === 'Proactif (MIP)'" v-html="charte_partenariat_proactif"></div>
			<div v-if="client.fields['Classe de partenaire'] === 'Proactif ÉcoSystème (MI+)'" v-html="charte_partenariat_proactif_plus" class="column2"></div>
		</div>
		<div class="signature">
			<div class="confirmation">
				<div class="check" v-if="client.fields['Classe de partenaire'].includes('(MIP)')">
					<i class="fad fa-check"></i>
					J'adhère au Réseau Proactif et à tous ses avantages et je m'acquitte de la cotisation annuelle de 325€ HT
				</div>
				<div class="check" v-if="client.fields['Classe de partenaire'].includes('(MI+)')">
					<i class="fad fa-check"></i>
					J'adhère au Réseau Proactif et à tous ses avantages et je m'acquitte de la cotisation annuelle de 950€ HT
				</div>
				<div class="check" v-if="client.fields['Classe de partenaire'].includes('Proactif')">
					<i class="fad fa-check"></i>
					J'ai pris connaissance et j'accepte les conditions générales de vente et de garantie VIESSMANN, la charte de partenariat Proactif VIESSMANN et les
					conditions générales du réseau Proactif jointes à ce dossier
				</div>
				<div class="check" v-if="client.fields['Classe de partenaire'].includes('Partenaire')">
					<i class="fad fa-check"></i>
					J'ai pris connaissance et j'accepte la charte de partenariat VIESSMANN
				</div>

				<div
					class="check"
					v-if="
						(client.fields['Classe de partenaire'].includes('Proactif') || client.fields['Classe de partenaire'].includes('Partenaire')) &&
							client.fields.lead_management
					"
				>
					<i class="fad fa-check"></i>
					J’ai pris connaissance et j'accepte les termes et conditions de la charte lead management
				</div>
				<div
					class="check"
					v-if="(client.fields['Classe de partenaire'].includes('Proactif') || client.fields['Classe de partenaire'].includes('Partenaire')) &&
						client.fields.challenge_pompe_chaleur
						"
				>
					<i class="fad fa-check"></i>
					Je suis intéressé pour participer à la convention nationale Proactif 2025
				</div>
				<div
					class="check"
					v-if="
						(client.fields['Classe de partenaire'].includes('Proactif') || client.fields['Classe de partenaire'].includes('Partenaire')) &&
							client.fields['Engagement 48h']
					"
				>
					<i class="fad fa-check"></i>
					Je m'engage à répondre dans les 48h à la proposition de prise en charge des projets que Viessmann m'enverra, contacter rapidement le client final,
					renseigner le processus d'évolution de ces demandes jusqu'à leur conversion en vente.
				</div>

				<small>* En cas de litige financier, Viessmann se réserve le droit de suspendre provisoirement l’affichage sur son site.</small>
			</div>
			<div class="droite">
				<div class="signataire">
					<h2>Client <span>(Dirigeant ou Gérant)</span></h2>
					<div class="data">
						<label>Entreprise</label>
						{{ client.fields["Société"] }}
					</div>
					<div class="data">
						<label>Numéro de compte</label>
						{{ client.fields["Numéro client"] }}
					</div>
					<div class="data">
						<label>Signataire</label>
						{{ client.fields["Prénom signataire"] }}
						{{ client.fields["Nom signataire"] }}
					</div>
					<div class="data">
						<label>Adresse</label>
						<address>
							{{ client.fields["Adresse"] }}
							<span v-if="client.fields['Complément d\'adresse'] && client.fields['Complément d\'adresse'].length > 4"
								><br />{{ client.fields["Complément d'adresse"] }}</span
							>
							<br />{{ client.fields["Code Postal"] }} {{ client.fields["Ville"] }}
						</address>
					</div>
					<div class="data">
						<label>Email</label>
						{{ client.fields["Email signataire"] }}
					</div>
					<div class="data sign">
						<label>Signature</label>
						<div>&nbsp;</div>
					</div>
					<h2>ATC Viessmann</h2>
					<div class="data">
						{{ client.fields["prenom_commercial"][0] }}
						{{ client.fields["nom_commercial"][0] }}
						{{ client.fields.email_commercial[0] }}
					</div>
				</div>
			</div>
		</div>

		<Pieddepage :page="3" />
	</div>
</template>
<script>
import Pieddepage from "../pages/Pieddepage";
export default {
	name: "page5",
	components: { Pieddepage },
	data() {
		return {};
	},
	computed: {
		charte_partenariat_proactif_plus() {
			return this.$store.state.chartes[0].fields.charte_partenariat_proactif_plus;
		},
		charte_partenariat_proactif() {
			return this.$store.state.chartes[0].fields.charte_partenariat_proactif;
		},
		charte_partenariat_prospect() {
			return this.$store.state.chartes[0].fields.charte_partenariat_prospect;
		},
		charte_partenariat_partenaire() {
			return this.$store.state.chartes[0].fields.charte_partenariat_partenaire;
		},
		charte_partenariat_basic() {
			return this.$store.state.chartes[0].fields.charte_partenariat_basic;
		},
		client() {
			return this.$store.state.current_client;
		}
	},
	methods: {}
};
</script>

<style lang="scss">
.pageprint {
	display: block;
	position: fixed;
	background-color: white !important;
	width: 1512px;
	height: 2088px;
	padding: 80px;
	font-size: 16px;
	line-height: 1.8;
	margin: 0;
	box-sizing: border-box;
	box-shadow: unset !important;
	top: 0;
	left: 0;
	z-index: 9999;
	h1 {
		font-size: 40px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	h2 {
		background: rgba(black, 0.05);
		border-top: solid 1px black;
		border-bottom: solid 1px black;
		padding: 10px;
		font-size: 26px;
	}
	h3 {
		font-weight: bold;
		font-size: 22px;
		margin: 26px 0 8px;
		padding: 0 10px;
	}
	.sep {
		width: 100%;
		height: 50px;
	}
	.data {
		display: flex;
		justify-content: space-between;
		font-size: 18px;
		font-weight: bold;
		align-items: center;
		border-bottom: dashed 1px rgba(black, 0.1);
		padding: 4px 10px;
		label {
			font-weight: normal;
		}
	}
	.liste_formations {
		.formation {
			display: flex;
			flex-wrap: wrap;
			font-size: 20px;
			padding: 10px 0;
			position: relative;
			border-bottom: solid 1px rgba(black, 0.05);
			.nom {
				font-weight: bold;
				padding: 3px;
				flex: 0 0 100%;
			}
			.stagiaire,
			.email,
			.portable,
			.fonction,
			.trimestre {
				padding: 2px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
	}
}
#page5 {
	.data {
		address {
			text-align: right;
		}
	}
	.zone-texte {
		h2 {
			font-size: 40px;
			line-height: 50px;
			margin-right: 40px;
			color: lighten(black, 60%);
			padding: 0;
			border: none;
			font-weight: normal;
			background: none;
			margin-bottom: 20px;
		}
		p {
			&:first-of-type {
				font-weight: bold;
				font-size: 20px;
				line-height: 30px;
				margin-bottom: 40px;
			}
		}
		strong {
			font-size: 18px;
		}
		
		ul {
			li {
				font-size: 16px;
				line-height: 30px;
				padding-left: 20px;
				position: relative;
				&:before {
					content: "";
					width: 10px;
					height: 10px;
					position: absolute;
					left: 0;
					top: 10px;
					background: $rouge;
				}
				a {
					color: $rouge;
				}
			}
		}
		.column2 {
			columns: 2;
			font-size: 15px;
			line-height: 26px;
			gap: 30px;
			ul li {
				font-size: 15px;
				line-height: 26px;
			}
		}
	}
	.signature {
		display: flex;
		align-items: flex-end;
		position: absolute;
		left: 80px;
		bottom: 130px;
		right: 80px;
		.confirmation {
			flex: 2;
			padding-right: 50px;
			.check {
				margin-bottom: 20px;
				position: relative;
				display: flex;
				align-items: flex-start;
				i,
				svg {
					color: $rouge;
					font-size: 20px;
					margin-right: 10px;
					margin-top: 5px;
				}
			}
			h2 {
				font-size: 20px;
				border: none;
				padding: 0 10px;
				background: transparent;
				span {
					font-weight: normal;
					font-size: 14px;
				}
			}
			small {
				margin-top: 10px;
				display: block;
				padding-left: 30px;
			}
			.data {
				font-size: 16px;
			}
			.recompense {
				margin-top: 20px;
				padding-left: 20px;
			}
		}
		.droite {
			flex: 3;
			background: lighten(black, 96%);
			padding: 20px;
			font-size: 16px;
			h2 {
				font-size: 20px;
				border: none;
				padding: 0 10px;
				background: transparent;
				span {
					font-weight: normal;
					font-size: 14px;
				}
			}
			.data {
				font-size: 16px;
				align-items: flex-start;
				&.sign {
					height: 180px;
				}
			}
		}
	}
}
</style>
