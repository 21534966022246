<template>
	<div class="footerpdf">
		<img class="logo" src="../../assets/images/logos/viessmann_wordmark_rgb_1_vitorange.png" />
		<div>Plan d'Action Commercial 2025 - Page {{ page }}</div>
	</div>
</template>
<script>
export default {
	name: "piedepage",
	props: {
		page: Number
	},
	components: {},
	data() {
		return {};
	},
	methods: {}
};
</script>

<style lang="scss">
.footerpdf {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 60px 80px;
	display: flex;
	justify-content: space-between;
	font-style: italic;
	color: grey;
	align-items: flex-end;
	.logo {
		width: 240px;
		transform: translate(-20px, 40px);
	}
}
</style>
