<template>
	<div class="pageprint" id="page9">
		<h2>Conditions de garantie contractuelle 2025</h2>

		<div class="deux-colonnes">
			<p><strong>1. Généralités</strong></p>

			<p>
				Le Vendeur garantit, sur la base de ses conditions générales de vente, la solidité et les performances de son Matériel de chauffage et de production
				d&rsquo;eau chaude sanitaire, de climatisation, de panneaux solaires (thermiques et photovoltaïques - PV), selon les termes de la déclaration ci-dessous
			</p>

			<p>Le Vendeur garantit une fabrication parfaite du Matériel de chauffage et de production d&rsquo;eau chaude réalisé en matériaux de haute qualité.</p>

			<p>Il garantit que ses appareils atteignent la puissance indiquée sur ses notices.</p>

			<p>
				La garantie commence à la date de facture au Client ou à la mise en service du Matériel si elle est antérieure, et au plus tard 6 mois à partir de la date
				de facturation à l&rsquo;Acheteur. Ce délai est réduit à 3 mois pour les chaudières Vitomax et Vitoplex LS, si celles-ci sont uniquement livrées et par
				conséquent ne sont pas soumises aux dispositions des conditions spéciales de vente.
			</p>

			<p>
				Le Vendeur s&rsquo;engage à réparer ou à mettre à disposition gratuitement, pendant toute la durée de la garantie, toute pièce dont l&rsquo;Acheteur peut
				prouver qu&rsquo;un état antérieur au transfert de risque la rend inutilisable ou diminue grandement son utilité. Les pièces remplacées devront être
				retournées au Vendeur en franco de port. Les frais de démontage et de montage ainsi que les frais de port liés au remplacement des pièces faisant
				l&rsquo;objet de réclamations n&rsquo;entrent pas dans le cadre de la garantie. Toute responsabilité supplémentaire des défectuosités ou de quelconques
				dégâts directs ou indirects, de dommages et intérêts ou indemnités pour pertes de gains sont exclus.
			</p>

			<p>
				Pour certains produits, l&rsquo;Acheteur peut obtenir sur demande auprès du Vendeur une offre de garantie contractuelle plus large qui fera l&rsquo;objet
				d&rsquo;un contrat spécifique.
			</p>

			<p>Les extensions de garantie bénéficient des mêmes conditions que les garanties contractuelles, seule la durée est prolongée.</p>

			<p><strong>2. Conditions d&rsquo;installation d&rsquo;utilisation et d&rsquo;entretien</strong></p>

			<p>
				La garantie est accordée à condition que les Matériels que le Vendeur livre, soient installés par une entreprise spécialisée, selon les règles de
				l&rsquo;art et les dernières connaissances techniques, en respectant sa notice de montage, ses schémas d&rsquo;installation, ses notices pour l&rsquo;étude
				et les prescriptions des normes en vigueur. Ils doivent être stockés à l&rsquo;abri de l&rsquo;humidité et des intempéries. Les Matériels devront, par la
				suite, fonctionner et être entretenus par des professionnels en tenant compte de ses notices d&rsquo;utilisation, de maintenance, des normes et
				réglementations en vigueur.
			</p>

			<p>
				Plus particulièrement pour les modèles de pompes à chaleur et climatisation, split ou dont la charge en fluide dépasse la limite réglementaire, la garantie
				n&rsquo;est accordée que si ce matériel a été mis en service par un professionnel pouvant justifier de son &ldquo;Attestation de manipulation des fluides
				frigorigènes&rdquo; valide à ce moment. En effet, les entreprises devront être titulaires d&rsquo;une &ldquo;attestation de capacité&ldquo; et employer du
				personnel titulaire d&rsquo;une &ldquo;attestation d&rsquo;aptitude&ldquo; ; attestations qui devront être à jour au moment de la mise en service.
			</p>

			<p>
				La garantie est exclue si l&rsquo;Acheteur ou le Client procèdent ou font procéder à des modifications ou à des travaux de réparation sur les Matériels, par
				des personnes qui ne sont pas spécialisées et/ou en dehors des règles de l&rsquo;art et/ou sans l&rsquo;accord préalable du Vendeur.
			</p>

			<p>
				La garantie est exclue en cas de manque de contrôle et d&rsquo;entretien tous les ans de l&rsquo;ensemble du Matériel. Tout contrôle ou entretien devra être
				noté dans le carnet de chaufferie, le livret d&rsquo;entretien, ou justifié par l&rsquo;attestation d&rsquo;entretien.
			</p>

			<p>
				L&rsquo; ensemble de la documentation relative à l&rsquo;installation, l&rsquo;entretien et l&rsquo;utilisation du Matériel est disponible et mis à
				disposition à tout moment sur le site internet dédié aux professionnels.
			</p>

			<p><strong>3. Exclusions générales de garantie</strong></p>

			<p>
				La garantie ne couvre pas les pièces soumises à une usure normale tels que par exemple les turbulateurs, les réfractaires, les portes, les trappes et les
				joints, ainsi que les pièces définies comme devant être remplacées périodiquement dans le cadre d&rsquo;une maintenance normale.
			</p>

			<p>La garantie ne s&rsquo;étend pas aux dégâts provoqués par :</p>

			<p>a. Le manque d&rsquo;eau dans les chaudières, présence d&rsquo;air, mauvaise purge, gel ou fluide caloporteur inadéquat.</p>

			<p>
				b. L&rsquo;emploi de combustibles non appropriés pendant le fonctionnement des chaudières, en particulier pour celles de la gamme fioul à condensation
				Vitoladens 300-W/333-F et Vitolacaldens 222-F qui doivent utiliser exclusivement un combustible à faible teneur en soufre (&lt; 50 ppm). En cas de
				fonctionnement au fioul lourd, il faudra consulter le Vendeur. Il faudra apporter un soin tout particulier au mélange des eaux de retour pour éviter que la
				température de celles-ci ne soit inférieure à 80&deg;C.
			</p>

			<p>
				c. La corrosion par point de rosée des surfaces de chauffe causée par des températures de chaudières trop basses. Sauf stipulation contraire selon les
				modèles, les chaudières devront être équipées d&rsquo;une vanne mélangeuse 4 voies, sur laquelle sera installée une régulation de marque Viessmann ou de
				conception identique. Pour les chaudières murales, un débit d&rsquo;irrigation doit être respecté (voir nos documents techniques). Sauf stipulation
				contraire selon les modèles, pour les
			</p>

			<p>
				Matériels de moyenne et grande puissance hors chaudières à condensation, prévoir une pompe de rehaussement des retours qui assurera à tout moment une
				température des retours évitant les condensations acides dans le foyer, ou une pompe d&rsquo;irrigation assurant un débit minimal d&rsquo;eau de chaudière
				(voir nos documents techniques),
			</p>

			<p>
				d. Les dépôts ou les précipitations de minéraux, de boues ou d&rsquo;autres corps étrangers ainsi que la corrosion causée par l&rsquo;oxygène de l&rsquo;air
				dissous dans l&rsquo;eau de chaudière ou des appoints d&rsquo;eau de chauffage anormaux.
			</p>

			<p>e. L&rsquo;absence de pot à boues, de filtre sur le retour chaudière.</p>

			<p>
				f. Une absence de désembouage préalable, suivi d&rsquo;un rinçage et d&rsquo;un traitement inhibiteur de corrosion, notamment sur les anciennes
				installations.
			</p>

			<p>
				g. Une eau agressive, un mauvais traitement de l&rsquo;eau de chaudière, un remplissage des préparateurs avec de l&rsquo;eau qui ne remplit pas les
				conditions préconisées par le Vendeur ou qui a mal été traitée, un mauvais nettoyage mécanique d&rsquo;un préparateur. Pour les générateurs MGP, sans
				préconisation constructeur sur l&rsquo;eau de chauffage, les guides techniques du SYPRODEAU s&rsquo;appliquent par défaut (&ldquo;Guide Technique - Qualité
				de l&rsquo;eau des installations de chauffage dans les bâtiments tertiaires &amp; immeubles d&rsquo;habitation&rdquo; et &ldquo;Guide Technique - Traitement
				des eaux des circuits chauds froids dans les bâtiments - Boucle thermique 0-110&deg;C&rdquo;).
			</p>

			<p>
				h. L&rsquo;entartrage des échangeurs sanitaires dans les zones calcaires et le non- respect des titres hydrotimétriques requis (TA,TH,) et pH (potentiel
				hydrogène). Une eau sanitaire est considérée comme dure si son TH est supérieure à 25&deg;f. Un traitement de la dureté de l&rsquo;eau est nécessaire en cas
				de dépassement. Viessmann préconise un réglage entre 10 et 15&deg;f.
			</p>

			<p>
				i. L&rsquo;absence d&rsquo;une anode à courant imposé ou le non respect des polarités d&rsquo;une anode à courant imposé ou l&rsquo;absence d&rsquo;une
				anode au magnésium et le non remplacement régulier de cette dernière dans les préparateurs d&rsquo;eau chaude sanitaire émaillés.
			</p>

			<p>j. De l&rsquo;air impropre à la combustion comportant des hydrocarbures halogénés, des solvants, des aérosols, du chlore, des fluors, des vapeurs acides.</p>

			<p>
				k. La surpression causée par l&rsquo;absence de soupape de sécurité, un dysfonctionnement ou un mauvais montage et/ou un mauvais réglage, un dimensionnement
				inadéquat.
			</p>

			<p>
				l. Des organes à commande électrique ou électronique raccordés par l&rsquo;installateur d&rsquo;une manière non conforme ou hors des règles de l&rsquo;art :
				les régulations, les organes de commande, les régulateurs de brûleur, les pompes, organes de sécurité etc.
			</p>

			<p>m. L&rsquo;absence des organes de sécurité réglementaire ou leur dysfonctionnement.</p>

			<p>n. Une utilisation incompatible avec la destination normale du Matériel.</p>

			<p>
				Les défectuosités qui se produisent devront être communiquées au Vendeur par écrit et sans délai, en lui indiquant précisément les défectuosités constatées,
				faute de quoi, la garantie sera exclue. Il devra être mis en mesure, dans un délai convenable, de constater sur place le défaut relevé et de rechercher la
				cause des réclamations pendant le fonctionnement du Matériel. Le Vendeur a le choix des moyens à employer pour remédier au défaut - réparation,
				modifications, remplacements. Il n&rsquo;acceptera aucun retour de Matériel sans l&rsquo;avoir préalablement autorisé par écrit. Dans le cas où les
				défectuosités risquent de provoquer des dégâts, l&rsquo;Acheteur et/ou le Client doivent immédiatement mettre ou faire mettre le Matériel hors service ou en
				modifier le mode de fonctionnement de telle sorte, que cela empêche les dégâts de s&rsquo;accroître et/ou empêche des dégâts consécutifs aux premiers de se
				produire.
			</p>

			<p>
				Un délai convenable doit être accordé au Vendeur pour procéder aux modifications et aux livraisons de remplacement. Si ce délai lui est refusé, il sera
				dégagé de toute responsabilité.
			</p>

			<p>Exclusions spécifiques pour les panneaux solaires thermiques.</p>

			<p>La garantie ne couvre pas les dégâts causés par :</p>

			<p>a. Le non-respect des avis techniques (Atec),</p>

			<p>
				b. L&rsquo;entartrage ou la corrosion provoquée par un fluide caloporteur inadéquat, une détérioration du fluide due à des surchauffes anormales ou des
				dépôts de corps étrangers dans le circuit capteurs,
			</p>

			<p>c. Le gel si le fluide caloporteur est non approprié ou non vidangé pour éviter le risque,</p>

			<p>
				d. La surchauffe du fluide solaire due à des conditions d&rsquo;exploitation anormales, un arrêt prolongé de l&rsquo;installation sans vidange ou une
				exposition prolongée sans fluide,
			</p>

			<p>e. Des chutes d&rsquo;objets sur la vitre, des intempéries, des catastrophes naturelles tels que tempête, grêle, ouragan, etc...,</p>

			<p>f. Une couche de neige trop importante. L&rsquo;inclinaison des panneaux doit permettre de limiter la couche restant sur les capteurs,</p>

			<p>
				g. Des conditions d&rsquo;utilisation inhabituelles, un dimensionnement de la surface des capteurs inadapté au réservoir de stockage ou aux besoins réels
				d&rsquo;eau chaude sanitaire, une inutilisation prolongée de l&rsquo;installation sans décharge possible, une expansion inadaptée (volume et pression du
				vase d&rsquo;expansion),
			</p>
			<p>h. Le manque d&rsquo;inspection, d&rsquo;entretien et de nettoyage,</p>

			<p>i. La détérioration par la foudre, coupure de l&rsquo;alimentation électrique,</p>

			<p>
				j. La corrosion du caisson : dans les régions de bord de mer ou dans les régions où des usines rendent l&rsquo;air particulièrement agressif, (si le caisson
				n&rsquo;est pas régulièrement entretenu par un procédé anti-corrosif efficace),
			</p>

			<p><strong>4. Durée de la garantie contractuelle et Matériel concerné :</strong></p>

			<p>
				La liste qui suit indique les produits et leur durée de garantie contractuelle. Les pièces dites d&rsquo;usure ainsi que les consommables usuels en sont
				exclus.
			</p>

			<p>a. Chaudières murales, chaudières compactes au sol (type Vitodens Vitoladens) : 2 ans</p>

			<p>b. Chaudières au sol (hors celles visées en J) et échangeurs sur gaz de fumées : 3 ans sur le corps de chauffe</p>

			<p>c. Pompes à chaleur et préparateurs ECS thermodynamique: 2 ans</p>

			<p>d. PAC hybrides : 2 ans</p>

			<p>e. Préparateurs ECS cellulaires, séparés ou indépendants non intégrés dans un générateur: 5 ans</p>

			<p>f. Echangeur de chaleur, module thermique d&rsquo;appartement et module de production ECS instantanée: 2 ans</p>

			<p>
				g.Brûleurs, régulations, interfaces de communication, tableaux électriques de chaudières, servo-moteurs, accessoires électriques et autres accessoires: 2
				ans
			</p>

			<p>h.Onduleurs: 2 ans</p>

			<p>i. Panneaux solaires thermiques: 3 ans</p>

			<p>
				j. Vitomax, Vitoplex LS, biomasse gamme Viessmann Holzheiztechnik GmbH, pompes à chaleur gamme KWT et cogénération gamme Viessmann Kraft-Wärme- Kopplung
				GmbH : 2 ans sauf conditions de garantie spécifiques sur demande.
			</p>

			<p>Ces présentes garanties contractuelles complètent les garanties légales.</p>
		</div>

		<Pieddepage :page="7" />
	</div>
</template>
<script>
import Pieddepage from "../pages/Pieddepage";
export default {
	name: "page9",
	components: { Pieddepage },
	data() {
		return {};
	},

	methods: {}
};
</script>

<style lang="scss">
.pageprint {
	display: block;
	position: fixed;
	background-color: white !important;
	width: 1512px;
	height: 2088px;
	padding: 80px;
	font-size: 16px;
	line-height: 1.8;
	margin: 0;
	box-sizing: border-box;
	box-shadow: unset !important;
	top: 0;
	left: 0;
	z-index: 9999;
	h1 {
		font-size: 40px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	h2 {
		background: rgba(black, 0.05);
		border-top: solid 1px black;
		border-bottom: solid 1px black;
		padding: 10px;
		font-size: 26px;
	}
	h3 {
		font-weight: bold;
		font-size: 22px;
		margin: 26px 0 8px;
		padding: 0 10px;
	}
	.sep {
		width: 100%;
		height: 50px;
	}
	.data {
		display: flex;
		justify-content: space-between;
		font-size: 18px;
		font-weight: bold;
		align-items: center;
		border-bottom: dashed 1px rgba(black, 0.1);
		padding: 4px 10px;
		label {
			font-weight: normal;
		}
	}
	.liste_formations {
		.formation {
			display: flex;
			flex-wrap: wrap;
			font-size: 20px;
			padding: 10px 0;
			position: relative;
			border-bottom: solid 1px rgba(black, 0.05);
			.nom {
				font-weight: bold;
				padding: 3px;
				flex: 0 0 100%;
			}
			.stagiaire,
			.email,
			.portable,
			.fonction,
			.trimestre {
				padding: 2px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
	}
}
#page9 {
	.data {
		address {
			text-align: right;
		}
	}
	.deux-colonnes {
		margin-top: 40px;
		column-count: 2;
		column-gap: 100px;
		font-size: 16px;
		line-height: 21px;
		text-align: justify;
		strong {
			margin-top: 10px;
			display: block;
		}
	}
}
</style>
