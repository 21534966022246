<template>
	<div class="pageprint" id="page10">
		<h2>Conditions de garantie contractuelle 2025</h2>

		<div class="deux-colonnes">
			<p>h. Le manque d&rsquo;inspection, d&rsquo;entretien et de nettoyage,</p>

			<p>i. La détérioration par la foudre, coupure de l&rsquo;alimentation électrique,</p>

			<p><strong>5. Conditions spécifiques aux panneaux photovoltaïques :</strong></p>

			<p>
				Chaque panneau photovoltaïque de la série &laquo; Vitovolt 300 &raquo;, ci-après dénommé &laquo;Module PV&raquo;, et conçu par Viessmann Werke GmbH &amp;
				Co. KG, ci-après dénommée &laquo; Viessmann &raquo;, répond aux normes de fabrication et de qualité les plus strictes et fait l&rsquo;objet de tests de
				fonctionnalité et de performances dans le cadre d&rsquo;une inspection finale. Par conséquent, Viessmann fournit les garanties de produit et de performances
				suivantes pour ce Module PV. Les clients de Viessmann ayant acheté le Module PV auprès de Viessmann, ci-après dénommés &laquo; Partenaires commerciaux
				Viessmann &raquo;, ont le droit de présenter des réclamations au titre de ces garanties.
			</p>

			<p>a. Garantie produit</p>

			<p>
				Dès la livraison du produit au Partenaire commercial, Viessmann offre une garantie de quinze ans sur tous les composants des modules et leur fabrication
				couvrant l&rsquo;apparition de défauts matériels ou de fabrication importants. Si le Module PV présente un défaut matériel ou de fabrication important au
				cours de la période couverte par la garantie, Viessmann y remédiera au moyen d&rsquo;une réparation, d&rsquo;un remplacement ou de toute autre solution
				qu&rsquo;elle choisira à sa seule discrétion. Le Partenaire commercial Viessmann doit fournir la preuve du défaut matériel par le biais d&rsquo;un test
				matériel reconnu par Viessmann. Les défauts visuels ne constituent en aucun cas un défaut matériel important. Afin d&rsquo;écarter tout doute, il est
				expressément indiqué que les Modules PV et tous les matériaux exposés à des conditions environnementales variables sont soumis à une dégénérescence
				(vieillissement) naturelle et que leur apparence peut changer au fil de leur utilisation.
			</p>

			<p>
				Toute réclamation de garantie ne rentrant pas dans le cadre décrit ci-dessus, notamment les demandes de remboursement de frais de déplacement, de tests
				matériels, de démontage et d&rsquo;installation, d&rsquo;inspection sur site ou autres frais indirects ou dommages-intérêts, ne sera pas prise en compte.
				Aucune réparation, livraison de remplacement ou indemnisation sous toute autre forme ne saurait prolonger la présente garantie.
			</p>

			<p>b. Garantie de performances</p>

			<p>
				Viessmann garantit au Partenaire commercial Viessmann que les performances réelles de ce Module PV ne diminueront pas de plus de 2 % au cours de la première
				année d&rsquo;utilisation par rapport à la puissance nominale indiquée sur la feuille technique et sur l&rsquo;étiquette du Module PV dans des conditions
				STC (conditions de test normalisées, définies comme suit : rayonnement de 1 000 W/m2, nombre de masse atmosphérique de 1,5 AM et température de cellule de
				25 &deg;C). À partir de la deuxième année et jusqu&rsquo;à la fin de la 25ème année d&rsquo;utilisation, Viessmann garantit que les performances ne
				diminueront pas de plus de 0,55 % supplémentaire par an par rapport à la puissance nominale d&rsquo;origine, de sorte qu&rsquo;à la fin de la 25ème année
				d&rsquo;utilisation, le Module PV affichera toujours des performances de 84,8% au minimum par rapport à la puissance nominale spécifiée dans des conditions
				STC.
			</p>

			<p>
				La présente garantie de performances couvre uniquement la diminution des performances due à la dégénérescence (vieillissement) naturelle des cellules, des
				feuilles ou du verre. Toute diminution des performances résultant de défauts du produit ou de fabrication ne rentre pas dans le cadre de la garantie de
			</p>

			<p>performances.</p>

			<p>
				Si le Module PV n&rsquo;atteint pas les niveaux de performances minimum garantis ci- dessus pendant la période de garantie correspondante sur la base
				d&rsquo;un test de performances approprié réalisé par le Partenaire commercial Viessmann, Viessmann remédiera à cette diminution des performances à sa
				discrétion au moyen d&rsquo;une réparation, d&rsquo;un remplacement ou de toute autre solution qu&rsquo;elle choisira à sa seule discrétion. Le test de
				performances doit être effectué dans les conditions STC (conditions de test normalisées, définies comme suit : rayonnement de 1 000 W/m2, nombre de masse
				atmosphérique de 1,5 AM et température de cellule de 25 &deg;C).
			</p>

			<p>
				Le test de performances doit être effectué conformément à la norme CEI 60904 par un laboratoire d&rsquo;essai autorisé à tester les Modules PV et certifié
				comme laboratoire d&rsquo;essai accrédité selon la norme DIN 17025. Lors du test de performances, les erreurs de mesure doivent être prises en compte
				conformément à la norme EN 50380.
			</p>

			<p>
				Toute réclamation de garantie concernant le remboursement de coûts tels que les frais de déplacement réels, de test de performances, de démontage et
				d&rsquo;installation, d&rsquo;inspection sur site ou autres frais indirects ou dommages-intérêts, ne sera pas prise en compte. Aucune réparation, livraison
				de remplacement ou indemnisation sous toute autre forme ne saurait prolonger la présente garantie.
			</p>

			<p>c. Exercice des droits découlant de la garantie produit et/ou de performances</p>

			<p>
				Les réclamations de garantie produit et/ou de performances doivent être envoyées (accompagnées d&rsquo;une copie de la facture correspondante) à
				l&rsquo;adresse de contact ci-dessous par le Partenaire commercial Viessmann concerné pendant la période de garantie dès que les faits à l&rsquo;origine de
				la réclamation se manifestent.
			</p>

			<p>
				Si les droits découlant de la présente garantie sont transférés à un tiers par le Partenaire commercial Viessmann, la réclamation de garantie doit être
				traitée par l&rsquo;intermédiaire du Partenaire commercial Viessmann, qui prendra contact avec Viessmann, l&rsquo;informera des circonstances de la
				réclamation et recevra les paiements versés par Viessmann. Si le Partenaire commercial Viessmann cesse ses activités, est mis en liquidation ou fait
				faillite, le cessionnaire est en droit d&rsquo;entrer en contact direct avec Viessmann. Viessmann désignera alors un autre partenaire commercial par
				l&rsquo;intermédiaire duquel la réclamation de garantie sera traitée. Aucun traitement direct via Viessmann n&rsquo;est possible.
			</p>

			<p>
				Les réclamations de garantie présupposent que le Partenaire commercial Viessmann s&rsquo;est acquitté de son obligation légale d&rsquo;inspecter les
				marchandises et de signaler immédiatement tout défaut.
			</p>

			<p>d. Exclusions de garantie</p>

			<p>
				La diminution des performances et les défauts matériels et de fabrication résultant d&rsquo;un transport, d&rsquo;un stockage, d&rsquo;une manipulation,
				d&rsquo;une utilisation, d&rsquo;un raccordement, d&rsquo;un entretien ou d&rsquo;une influence tierce inapproprié(e) ne sont pas couverts par les garanties
				produit et de performances susmentionnées. Sont également exclus la diminution des performances et les défauts matériels et de fabrication résultant des
				conditions suivantes :
			</p>

			<p>- Utilisation de composants système défectueux (onduleurs, systèmes d&rsquo;installation, câbles de raccordement ou diodes à semi-conducteur).</p>

			<p>- Installation du Module PV effectuée par des personnes non qualifiées ou non formées.</p>

			<p>- Câblage du Module PV avec des Modules PV de construction différente ou provenant d&rsquo;un autre fabricant.</p>

			<p>- Câblage ou raccordement incorrect du Module PV ou manipulation incorrecte pendant l&rsquo;exécution de ces tâches.</p>

			<p>- Bris de verre dû à l&rsquo;application de forces extérieures, un acte de vandalisme ou un vol.</p>

			<p>- Utilisation du Module PV sur des objets mobiles tels que des véhicules, des bateaux ou des avions.</p>

			<p>
				- Catastrophes naturelles telles qu&rsquo;un tremblement de terre, un typhon, un ouragan, une éruption volcanique, une inondation, un impact direct ou
				indirect de la foudre, une charge de neige, une avalanche, un glissement de terrain ou toute autre circonstance imprévisible.
			</p>

			<p>- Interférence avec et modification du Module PV sans l&rsquo;autorisation expresse de Viessmann.</p>

			<p>- Utilisation du Module PV à une fin autre que celle prévue : production d&rsquo;électricité par rayonnement solaire.</p>

			<p>
				- Utilisation du Module PV dans des conditions environnementales défavorables, notamment en cas de contact avec de l&rsquo;eau salée, tempêtes de sable,
				surtensions et champs magnétiques.
			</p>

			<p>
				Les réclamations de garantie ne seront pas prises en compte s&rsquo;il manque le numéro de série du Module PV et/ou le logo Viessmann, ou si le Module PV ne
				peut être identifié de manière univoque en tant que Module PV Viessmann pour toute autre raison.
			</p>

			<p>
				Le retour d&rsquo;un micro-onduleur non-justifié (fonctionnement opérationnel constaté sans défaut) entraînera une facturation de 100 EUR pour frais de
				traitement.
			</p>
		</div>

		<Pieddepage :page="8" />
	</div>
</template>
<script>
import Pieddepage from "../pages/Pieddepage";
export default {
	name: "page10",
	components: { Pieddepage },
	data() {
		return {};
	},

	methods: {}
};
</script>

<style lang="scss">
.pageprint {
	display: block;
	position: fixed;
	background-color: white !important;
	width: 1512px;
	height: 2088px;
	padding: 80px;
	font-size: 16px;
	line-height: 1.8;
	margin: 0;
	box-sizing: border-box;
	box-shadow: unset !important;
	top: 0;
	left: 0;
	z-index: 9999;
	h1 {
		font-size: 40px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	h2 {
		background: rgba(black, 0.05);
		border-top: solid 1px black;
		border-bottom: solid 1px black;
		padding: 10px;
		font-size: 26px;
	}
	h3 {
		font-weight: bold;
		font-size: 22px;
		margin: 26px 0 8px;
		padding: 0 10px;
	}
	.sep {
		width: 100%;
		height: 50px;
	}
	.data {
		display: flex;
		justify-content: space-between;
		font-size: 18px;
		font-weight: bold;
		align-items: center;
		border-bottom: dashed 1px rgba(black, 0.1);
		padding: 4px 10px;
		label {
			font-weight: normal;
		}
	}
	.liste_formations {
		.formation {
			display: flex;
			flex-wrap: wrap;
			font-size: 20px;
			padding: 10px 0;
			position: relative;
			border-bottom: solid 1px rgba(black, 0.05);
			.nom {
				font-weight: bold;
				padding: 3px;
				flex: 0 0 100%;
			}
			.stagiaire,
			.email,
			.portable,
			.fonction,
			.trimestre {
				padding: 2px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
	}
}
#page10 {
	.data {
		address {
			text-align: right;
		}
	}
	.deux-colonnes {
		margin-top: 40px;
		column-count: 2;
		column-gap: 100px;
		font-size: 16px;
		line-height: 21px;
		text-align: justify;
		strong {
			margin-top: 10px;
			display: block;
		}
	}
}
</style>
